<template>
	<div>
		Landing Page
	<router-link to="/home" >
					home
				</router-link>

	</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped>

</style>

